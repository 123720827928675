function updateLeftWrapperPosition() {

    const container = 1440;
    const padding = 15;
    const offset = 3 * parseFloat(getComputedStyle(document.documentElement).fontSize);

    const threshold = container + 2 * offset + 2 * padding;

    const leftWrapper = document.querySelector('#navigationMain .leftwrapper');

    if (window.innerWidth < threshold) {
        leftWrapper.style.left = '15px';
    } else {
        leftWrapper.style.left = '';
    }
}

window.addEventListener('load', updateLeftWrapperPosition);
window.addEventListener('resize', updateLeftWrapperPosition);